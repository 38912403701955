import React, { Component } from "react";

import SayakLogo from "../../images/sayak_logo.png";

import "./dashboard-style.css";

import { Route } from "react-router-dom";
import { Row, Col, Menu, Dropdown, Button, Affix, Layout, Alert } from "antd";

import { Link, Router, withRouter } from "react-router-dom";
import {
  UserOutlined,
  PhoneOutlined,
  BookOutlined,
  QuestionCircleOutlined,
  StarOutlined,
} from "@ant-design/icons";
import ChangeLayoutUserDash from "./ChangeLayoutUserDash";

const { SubMenu } = Menu;
const { Header } = Layout;

class NavBarUser extends Component {
  state = {};

  handleLogout = (e) => {
    //e.preventDefault();

    if (this.props.userDetails.loginPlatform === "GOOGLE") {
      // console.log("proceed to logging Out with google");

      const appStateData = { message: "Logging Out Please Wait" };
      this.props.appBusySet(appStateData);
      this.props.logOutNonSayakUser(this.props.history);
    } else if (this.props.userDetails.loginPlatform === "FACEBOOK") {
      // console.log("proceed to logging Out with facebook");

      const appStateData = { message: "Logging Out Please Wait" };
      this.props.appBusySet(appStateData);
      this.props.logOutNonSayakUser(this.props.history);
    } else {
      const userData = this.props.userDetails.userName;
      const appStateData = { message: "Logging Out Please Wait" };
      this.props.appBusySet(appStateData);
      this.props.logOutSayakUser(
        userData,
        this.props.auth.userToken,
        this.props.history,
        this.props.endpoints
      );
    }
  };

  handleMenuItemClicked = (e) => {
    // console.log("mah ho hai", e)
    //this.setState({mainMenuSelectedItem : e.key})
    // this.props.updateMainNav('1')
    this.props.updateMainNav(e.key);
  };

  render() {
    let userDropDownMenu = (
      <div class="user-menu-dropdown">
        <Menu>
          <Menu.Item
            key="1"
            icon={<UserOutlined />}
            onClick={this.handleMenuItemClicked}
          >
            <Link to="/profile">
              <UserOutlined />
              Profile
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="2" icon={<UserOutlined />} onClick={this.handleMenuItemClicked} >
            <Link to="/stat">
              <UserOutlined />Statistics
            </Link>
          </Menu.Item> */}
          <Menu.Item
            key="2"
            icon={<UserOutlined />}
            onClick={this.handleLogout}
          >
            <UserOutlined />
            Logoutt
          </Menu.Item>
        </Menu>
      </div>
    );

    return (
      <div>
        <Row type="flex" justify="space-between" align="bottom">
          {/* <Col
            span={4}
            // xs={{ span: 12 }}
            // sm={{ span: 12 }}
            // md={{ span: 12 }}
            // lg={{ span: 4 }}
            // xl={{ span: 4 }}
            // xxl={{ span: 4 }}
          >
            <div className="logo-section">
              <Link to="/">
                <img src={SayakLogo} className="logo-desktop" />
              </Link>
            </div>
          </Col> */}
          {/* <Col
            span={12}
            style={{ textAlign: "right" }}
          >
            




          </Col> */}
          <Col span={24} style={{ textAlign: "right" }}>
            <div className="user-nav-right-profile-container">
              <Dropdown.Button
                overlay={userDropDownMenu}
                icon={<UserOutlined />}
              >
                <Link to="/profile" className="">
                  {this.props.userDetails.name}
                </Link>
              </Dropdown.Button>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Header style={{ backgroundColor: "white" , width:"100%" , display:"flex" , justifyContent:"space-around" }}>
            <Menu theme="" mode="horizontal" selectedKeys={this.props.selectedItem} style={{fontSize:"1.2em"}} className="usernav-header-menu" >
              <Menu.Item key="1" onClick={this.handleMenuItemClicked} >
                <span><StarOutlined />नेपाली हिज्जे परीक्षण</span>
                <Link to="/user/spelling" />
              </Menu.Item>
              <Menu.Item key="2" onClick={this.handleMenuItemClicked} >
                <span><StarOutlined />नेपालीमा टाइप गर्नुहोस्।</span>
                <Link to="/user/typeInNepali" />
              </Menu.Item>
              <Menu.Item key="3" onClick={this.handleMenuItemClicked} >
                <span><StarOutlined />फन्ट रूपान्तरण</span>
                <Link to="/user/fontConversion" />
              </Menu.Item>
              <Menu.Item key="4" onClick={this.handleMenuItemClicked} >
                <span><BookOutlined />नेपाली शब्दकोश</span>
                <Link to="/user/dictSearch" />
              </Menu.Item>
            </Menu>
          </Header>
        </Row> */}
      </div>
    );
  }
}

export default NavBarUser;
