import React, { Component } from "react";

import { Card, Row, Col, Button, DatePicker, Select, Modal } from "antd";

import UserProductStatistics from "./StatisticsComponents/UserProductStatistics";

import { Link } from "react-router-dom";

import { r_GetUserDetails } from "../../helpers/Requesthelper/RequestFormatter";
import { configConsumerProps } from "antd/lib/config-provider";
import { compose } from "redux";
import { MUTATION_END_POINT } from "../../utils/Constants";
import {
  getValueWithToken,
  requestListWithToken,
} from "../../utils/axiosUtils/AxiosUtils";
import { QUERY_CONFIG } from "../../utils/axiosUtils/QueryHelperConfig";
import dayjs from "dayjs";
import LineCharts from "../../layout/publicLayout/charts/LineCharts";

export class UserProfile extends Component {
  state = {
    loadingUserDetails: true,
    userDetails: {},
    startDate: "",
    endDate: "",
    type: "",
    openServiceUsageModal: false, // Set as a boolean
  };

  componentDidMount() {
    console.log("user profile console props location", this.props);
    this.generateUserDetails();
  }

  generateUserDetails = async () => {
    const endPoint = MUTATION_END_POINT.GET_USER_PROFILE;
    try {
      this.setState({ loadingUserDetails: true });
      const response = await getValueWithToken(endPoint);
      this.setState({ loadingUserDetails: false, userDetails: response });
    } catch (error) {
      console.log(error);
    }
  };

  handleCancel = () => {
    this.setState({ openServiceUsageModal: false }); // Close the modal
  };

  render() {
    return (
      <div className="dashboard-user-profile">
        <Card
          loading={this.state.loadingUserDetails}
          className="card-box-shadow-default"
        >
          <Row gutter={16}>
            <Col span={24}>
              <b>Name:</b>
              <p>{this.state.userDetails.fullName || "NA"}</p>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <b>Email:</b>
              <p>{this.state.userDetails.email || "NA"}</p>
            </Col>
            <Col span={12}>
              <b>UserName:</b>
              <p>{this.state.userDetails.username || "NA"}</p>
            </Col>
          </Row>
        </Card>

        <Card className="card-box-shadow-default">
          <p style={{ fontWeight: "bold", fontSize: "20px" }}>Service Usage</p>
          <Button
            onClick={() => this.setState({ openServiceUsageModal: true })} // Open the modal
            type="primary"
          >
            Usage
          </Button>
        </Card>

        <Modal
          title="Service Usage"
          open={this.state.openServiceUsageModal} // Correctly reference the state variable
          onCancel={this.handleCancel}
          footer={null}
        >
          <p>Hello</p>
        </Modal>
      </div>
    );
  }
}
