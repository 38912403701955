import axios from "axios";
import { url } from "../url";

export function signinRequest(request, endPoint) {
  const { BASE_END_POINT } = url;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${BASE_END_POINT}/${endPoint}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(request),
    })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          // Handle specific HTTP error codes
          if (error.response.status === 503) {
            reject(
              "तपाईंले अनुरोध गर्नुभएको लिङ्कमा कुनै डाटा उपलब्ध भएन । कृपया एडमिन लाई सम्पर्क गर्नुहोला ।"
            );
            // Handle 503 error
          } else if (error.response.status === 404) {
            reject("तपाईंले अनुरोध गर्नुभएको पेज भेटिएन ।");
            // Handle 404 error
          } else if (error.response.status === 400) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (
            error.response.status !== 200 &&
            error.response.data.status === "fail"
          ) {
            // localStorage.clear();
            localStorage.removeItem("saayakusertoken");
            window.location.reload();
            localStorage.setItem("expiredStatus", true);
          } else {
            reject(
              "तपाईंको नेटवर्क मा समस्या देखिएकोले यो अनुरोध पुरा हुन सकेन । इन्टरनेट कनेक्सन जाँच गरेर पुन प्रयास गर्नुहोला । "
            );
            console.log("error Response", error.response);
            // Handle other error codes
          }
        } else if (error.request) {
          console.log("Network error");
          // The request was made but no response was received
          reject("Request:", "Network Error");
        } else {
          // Something happened in setting up the request that triggered an error
          reject("Error:", error.message);
        }
        // reject(error);
      });
  });
}

export function signUpRequest(request, endPoint) {
  const { BASE_END_POINT } = url;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${BASE_END_POINT}/${endPoint}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(request),
    })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          // Handle specific HTTP error codes
          if (error.response.status === 503) {
            reject(
              "तपाईंले अनुरोध गर्नुभएको लिङ्कमा कुनै डाटा उपलब्ध भएन । कृपया एडमिन लाई सम्पर्क गर्नुहोला ।"
            );
            // Handle 503 error
          } else if (error.response.status === 404) {
            reject("तपाईंले अनुरोध गर्नुभएको पेज भेटिएन ।");
            // Handle 404 error
          } else if (error.response.status === 400) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (
            error.response.status !== 200 &&
            error.response.data.status === "fail"
          ) {
            // localStorage.clear();
            localStorage.removeItem("saayakusertoken");
            window.location.reload();
            localStorage.setItem("expiredStatus", true);
          } else {
            reject(
              "तपाईंको नेटवर्क मा समस्या देखिएकोले यो अनुरोध पुरा हुन सकेन । इन्टरनेट कनेक्सन जाँच गरेर पुन प्रयास गर्नुहोला । "
            );
            console.log("error Response", error.response);
            // Handle other error codes
          }
        } else if (error.request) {
          console.log("Network error");
          // The request was made but no response was received
          reject("Request:", "Network Error");
        } else {
          // Something happened in setting up the request that triggered an error
          reject("Error:", error.message);
        }
        // reject(error);
      });
  });
}

export function mutateValueWithoutFile(request, endPoint) {
  const { BASE_END_POINT } = url;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${BASE_END_POINT}/${endPoint}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(request),
    })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          // Handle specific HTTP error codes
          if (error.response.status === 503) {
            reject(
              "तपाईंले अनुरोध गर्नुभएको लिङ्कमा कुनै डाटा उपलब्ध भएन । कृपया एडमिन लाई सम्पर्क गर्नुहोला ।"
            );
            // Handle 503 error
          } else if (error.response.status === 404) {
            reject("तपाईंले अनुरोध गर्नुभएको सेवा पुरा हुन सकेन ।");
            // Handle 404 error
          } else if (error.response.status === 400) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (error.response.status === 429) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (
            error.response.status !== 200 &&
            error.response.data.status === "fail"
          ) {
            // localStorage.clear();
            localStorage.removeItem("saayakusertoken");

            window.location.reload();
            localStorage.setItem("expiredStatus", true);
          } else {
            reject(
              "तपाईंको नेटवर्क मा समस्या देखिएकोले यो अनुरोध पुरा हुन सकेन । इन्टरनेट कनेक्सन जाँच गरेर पुन प्रयास गर्नुहोला । "
            );
            console.log("error Response", error.response);
            // Handle other error codes
          }
        } else if (error.request) {
          console.log("Network error");
          // The request was made but no response was received
          reject("Request:", "Network Error");
        } else {
          // Something happened in setting up the request that triggered an error
          reject("Error:", error.message);
        }
        // reject(error);
      });
  });
}

export function signOutRequest(endPoint) {
  const { BASE_END_POINT } = url;
  const authToken = localStorage.getItem("sayakusertoken");
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${BASE_END_POINT}/${endPoint}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          // Handle specific HTTP error codes
          if (error.response.status === 503) {
            reject(
              "तपाईंले अनुरोध गर्नुभएको लिङ्कमा कुनै डाटा उपलब्ध भएन । कृपया एडमिन लाई सम्पर्क गर्नुहोला ।"
            );
            // Handle 503 error
          } else if (error.response.status === 404) {
            reject("तपाईंले अनुरोध गर्नुभएको सेवा पुरा हुन सकेन ।");
            // Handle 404 error
          } else if (error.response.status === 400) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (
            error.response.status !== 200 &&
            error.response.data.status === "fail"
          ) {
            localStorage.r();
            window.location.reload();
            localStorage.setItem("expiredStatus", true);
          } else {
            reject(
              "तपाईंको नेटवर्क मा समस्या देखिएकोले यो अनुरोध पुरा हुन सकेन । इन्टरनेट कनेक्सन जाँच गरेर पुन प्रयास गर्नुहोला । "
            );
            console.log("error Response", error.response);
            // Handle other error codes
          }
        } else if (error.request) {
          console.log("Network error");
          // The request was made but no response was received
          reject("Request:", "Network Error");
        } else {
          // Something happened in setting up the request that triggered an error
          reject("Error:", error.message);
        }
        // reject(error);
      });
  });
}

export function getValueWithToken(endPoint) {
  const { BASE_END_POINT } = url;
  const authToken = localStorage.getItem("sayakusertoken");
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${BASE_END_POINT}/${endPoint}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          // Handle specific HTTP error codes
          if (error.response.status === 503) {
            reject(
              "तपाईंले अनुरोध गर्नुभएको लिङ्कमा कुनै डाटा उपलब्ध भएन । कृपया एडमिन लाई सम्पर्क गर्नुहोला ।"
            );
            // Handle 503 error
          } else if (error.response.status === 404) {
            reject("तपाईंले अनुरोध गर्नुभएको सेवा पुरा हुन सकेन ।");
            // Handle 404 error
          } else if (error.response.status === 400) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (
            error.response.status !== 200 &&
            error.response.data.status === "fail"
          ) {
            // localStorage.clear();
            localStorage.removeItem("saayakusertoken");
            window.location.reload();
            localStorage.setItem("expiredStatus", true);
          } else {
            reject(
              "तपाईंको नेटवर्क मा समस्या देखिएकोले यो अनुरोध पुरा हुन सकेन । इन्टरनेट कनेक्सन जाँच गरेर पुन प्रयास गर्नुहोला । "
            );
            console.log("error Response", error.response);
            // Handle other error codes
          }
        } else if (error.request) {
          console.log("Network error");
          // The request was made but no response was received
          reject("Request:", "Network Error");
        } else {
          // Something happened in setting up the request that triggered an error
          reject("Error:", error.message);
        }
        // reject(error);
      });
  });
}

export function requestListWithToken({ endPoint, request }) {
  const { BASE_END_POINT } = url;
  const authToken = localStorage.getItem("sayakusertoken");
  const requestData = JSON.stringify(request);
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${BASE_END_POINT}/${endPoint}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: requestData,
    })
      .then((response) => {
        const { data } = response;
        if (response.status === 200 && data) {
          //console.log(response)
          resolve(response);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Handle specific HTTP error codes
          if (error.response.status === 503) {
            reject(
              "तपाईंले अनुरोध गर्नुभएको लिङ्कमा कुनै डाटा उपलब्ध भएन । कृपया एडमिन लाई सम्पर्क गर्नुहोला ।"
            );
            // Handle 503 error
          } else if (error.response.status === 404) {
            reject("तपाईंले अनुरोध गर्नुभएको सेवा पुरा हुन सकेन ।");
            // Handle 404 error
          } else if (error.response.status === 400) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (
            error.response.status !== 200 &&
            error.response.data.status === "fail"
          ) {
            // localStorage.clear();
            localStorage.removeItem("saayakusertoken");
            window.location.reload();
            localStorage.setItem("expiredStatus", true);
          } else {
            reject(
              "तपाईंको नेटवर्क मा समस्या देखिएकोले यो अनुरोध पुरा हुन सकेन । इन्टरनेट कनेक्सन जाँच गरेर पुन प्रयास गर्नुहोला । "
            );
            console.log("error Response", error.response);
            // Handle other error codes
          }
        } else if (error.request) {
          console.log("Network error");
          // The request was made but no response was received
          reject("Request:", "Network Error");
        } else {
          // Something happened in setting up the request that triggered an error
          reject("Error:", error.message);
        }
        // reject(error);
      });
  });
}
