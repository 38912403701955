import React from "react";
import { Button, message, Modal, notification } from "antd";
import axios from "axios";
import {} from "antd";
import { url } from "../../utils/url";

export const r_userFontConversion = (data, endpoints, accessToken) => {
  // const { FONT_CONVERT_END_POINT } = endpoints;

  return new Promise((resolve, reject) => {
    // let reqD = new FormData()
    // reqD.append("data",JSON.stringify(data))

    axios({
      method: "POST",
      url: url.FONT_CONVERT_END_POINT,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(data),
    })
      .then((res) => {
        const { data } = res;
        if (res.status === 200 && data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response) {
          // Handle specific HTTP error codes
          if (error.response.status === 503) {
            reject(
              "तपाईंले अनुरोध गर्नुभएको लिङ्कमा कुनै डाटा उपलब्ध भएन । कृपया एडमिन लाई सम्पर्क गर्नुहोला ।"
            );
            // Handle 503 error
          } else if (error.response.status === 404) {
            reject("तपाईंले अनुरोध गर्नुभएको सेवा पुरा हुन सकेन ।");
            // Handle 404 error
          } else if (error.response.status === 400) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (
            error.response.status !== 200 &&
            error.response.data.status === "fail"
          ) {
            // localStorage.clear();
            localStorage.removeItem("saayakusertoken");

            window.location.reload();
            localStorage.setItem("expiredStatus", true);
          } else {
            reject(error);
          }
        } else if (error.request) {
          console.log("Network error");
          // The request was made but no response was received
          reject("Request:", "Network Error");
        } else {
          // Something happened in setting up the request that triggered an error
          reject("Error:", error.message);
        }
      });
  }); //end of promise return
};
export const r_publicFontConversion = (data, endpoints) => {
  // const { FONT_CONVERT_END_POINT } = endpoints;
  return new Promise((resolve, reject) => {
    // let reqD = new FormData();
    // reqD.append("data", JSON.stringify(data));
    let authToken = localStorage.getItem("sayakusertoken");

    axios({
      method: "POST",
      url: url.FONT_CONVERT_END_POINT,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: JSON.stringify(data),
    })
      .then((res) => {
        const { data } = res;
        if (res.status === 200 && data) {
          resolve(res);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response) {
          // Handle specific HTTP error codes
          if (error.response.status === 503) {
            reject(
              "तपाईंले अनुरोध गर्नुभएको लिङ्कमा कुनै डाटा उपलब्ध भएन । कृपया एडमिन लाई सम्पर्क गर्नुहोला ।"
            );
            // Handle 503 error
          } else if (error.response.status === 404) {
            reject("तपाईंले अनुरोध गर्नुभएको सेवा पुरा हुन सकेन ।");
            // Handle 404 error
          } else if (error.response.status === 400) {
            reject(error.response.data.message);
            // Handle 404 error
          } else if (
            error.response.status !== 200 &&
            error.response.data.status === "fail"
          ) {
            // localStorage.clear();
            localStorage.removeItem("saayakusertoken");
            window.location.reload();
            localStorage.setItem("expiredStatus", true);
          } else {
            reject(error);
          }
        } else if (error.request) {
          console.log("Network error");
          // The request was made but no response was received
          reject("Request:", "Network Error");
        } else {
          // Something happened in setting up the request that triggered an error
          reject("Error:", error.message);
        }
      });
  }); //end of promise return
};
